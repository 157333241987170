import './App.css';
import Footer from './components/Footer/Footer';
import Hero from './components/hero/Hero';
import Join from './components/join/Join';
import Plan from './components/plan/Plan';
import Programs from './components/programs/Programs';
import Reason from './components/reasons/Reason';
import Testimonials from './components/Testimonials/Testimonials';

function App() {
  return (
    <div className="App">
          <Hero />
          <Programs />
          <Reason />
          <Plan />
          <Testimonials />
          <Join />
          <Footer />
    </div>
  );
}

export default App;
